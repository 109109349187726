import {
  HorizontalCard,
  HorizontalCards,
  EHorizontalCardLayout,
  EHorizontalCardBackground,
  RichText,
  toImageProps,
  ETagVariant,
  ETagSize,
  ETagTheme,
  EIconSize,
  EIconPosition,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React, { useMemo } from 'react';

export type THorizontalCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'horizontal-cards' }
>;

const HorizontalCardsConnected: React.FC<
  THorizontalCardsConnectedProps
> = props => {
  const { cards = [], ...rest } = props;

  const banners: React.ComponentProps<typeof HorizontalCard>[] = useMemo(() => {
    return cards.map(card => ({
      ...card,
      icon: toImageProps(card.icon),
      iconSize: card.iconSize as EIconSize,
      iconPosition: card.iconPosition as EIconPosition,
      description: <RichText content={card.description} />,
      tags: card.tags?.map(tag => ({
        children: tag.text,
      })),
      size: card.size as EHorizontalCardLayout,
      background: card.background as EHorizontalCardBackground,
      badges: card.badges?.map(badge => ({
        children: badge.text,
      })),
      badgesStyle: card.badgesStyle as ETagVariant,
      badgesSize: card.badgesSize as ETagSize,
      badgesTheme: card.badgesTheme as ETagTheme,
    }));
  }, [cards]);

  return <HorizontalCards banners={banners} {...rest} />;
};

export default HorizontalCardsConnected;
